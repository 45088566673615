import { Loading } from 'components/Loading'
import LoginAndSignUpForm from 'components/LoginAndSignUpForm'
import Layout from 'components/layouts/Layout'
import getSettings from 'lib/settings'
import { GetServerSideProps } from 'next'
import { getSession, useSession } from 'next-auth/client'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

const LoginPage = () => {
  const [session, loading] = useSession()
  const user = session?.user || null
  const router = useRouter()

  //redirect to home page if user is logged in
  useEffect(() => {
    if (user) {
      router.push('/')
    }
  }, [user])

  if (loading || user) {
    return (
      <div className={'mx-auto block'}>
        <Loading />
      </div>
    )
  }

  return (
    <div className={'flex w-full justify-center items-center flex-col'}>
      <h3
        className={'text-center text-3xl font-bold font-heading mt-3 md:mt-14'}
      >
        Welcome back
      </h3>
      <h4 className={'text-center font-sans font-medium text-sm mt-1'}>
        Please sign into your account below.
      </h4>
      <div
        className={'bg-white rounded-xl px-2 py-4 w-[344px] md:w-[448px] mt-8'}
      >
        <LoginAndSignUpForm defaultTab={'sign-in'} redirectUrl={'/'} />
      </div>
    </div>
  )
}

LoginPage.getLayout = function getLayout(page: React.ReactElement) {
  return <Layout>{page}</Layout>
}

export default LoginPage

export const getServerSideProps: GetServerSideProps = async (context) => {
  return {
    props: {
      session: await getSession(context),
      ...(await serverSideTranslations(context.locale || '')),
      ...(await getSettings()),
    },
  }
}
